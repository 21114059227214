  <template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <img class="img_brid img_brid1" src="../assets/imgs/brid.gif" alt="">
    <img class="img_brid img_brid2" src="../assets/imgs/brid.gif" alt="">
  </div>
</template>

<script>
// import 'assets/css/style.css'
import Head from "@/components/head_wm.vue";
import bgAnimateVue from "@/components/bg_animate.vue";

export default {
  data() {
    return {
      headTitle: "岳阳南湖新区新时代文明",
    };
  },
  components: {
    Head,
    bgAnimateVue,
  },
  computed: {},
  created() {
 
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.page {
  /* background: url(https://media.shouxinfund.com/yueyang/xinshidaiimg.png) no-repeat center; */
  background: url(https://home/ResImg/xinshidaiimg_Jc.gif) no-repeat center;
  background-size: 100% 100%;
}
.img_brid{
  width: 16%;
  height:20%;
}
.img_brid1{
  position: fixed;
  left:69%;
  top:4%;
}
.img_brid2{
  position: fixed;
  left:86%;
  top:-1%;
}
</style>